import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Icon, Image, Link, Text, Tooltip, chakra, useBreakpointValue } from '@chakra-ui/react';
import {
  FaArrowDown,
  FaArrowUp,
  FaCheckCircle,
  FaCopy,
  FaEdit,
  FaExternalLinkAlt,
  FaEye,
  FaTimesCircle,
  FaTrashAlt,
} from 'react-icons/fa';
import { FaClockRotateLeft } from 'react-icons/fa6';
import { MissingImageIcon } from '@netiva/classifieds-ui';

import { RouterLink } from '@/components/global';
import { PlatformIcon } from '@/components/ui';
import { useCurrencyFormatter, useDate } from '@/hooks';
import { routes } from '@/lib/routes';
import { CommonStepKeys } from '@/pages/Ad/constants';
import { ActionButton } from '../../ActionButton';
import { StatusBadge } from '../../StatusBadge';
import { TopListingStatus } from '../../TopListingStatus';
import { VerificationStatus } from '../../VerificationStatus';
import type { AdListItemActionFunc, AdListItem as AdListItemType } from '../../../types';

export type AdListItemProps = {
  item: AdListItemType;
  onActionExecuted: AdListItemActionFunc;
  showPlatform?: boolean;
  hasTopListingSlots: boolean;
};

const Container = chakra(Flex, {
  baseStyle: {
    justifyItems: 'stretch',
    position: 'relative',
    gap: 4,
  },
});

const ImageContainer = chakra(Box, {
  baseStyle: {
    position: 'relative',
    flex: { base: '0 0 100px', md: '0 0 200px' },
    height: { base: '100px', md: '200px' },
  },
});

const StyledImage = chakra(Image, {
  baseStyle: {
    width: 'full',
    height: 'full',
    objectFit: 'cover',
    objectPosition: '0 0',
    // inset a small amount to hide the border for colored print ads
    objectViewBox: 'inset(2px)',
    borderRadius: 'default',
  },
});

const DetailsContainer = chakra(Flex, {
  baseStyle: {
    flex: '1 1 auto',
    flexDirection: 'column',
    gap: 1,
  },
});
const ActionsContainer = chakra(Flex, {
  baseStyle: {
    flex: '0 0 auto',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

const StatusIcons = chakra(Flex, {
  baseStyle: {
    alignItems: 'center',
    position: 'absolute',
    top: 1,
    right: 1,
    backgroundColor: 'white',
    borderRadius: 'default',
  },
});

export const AdListItem: FC<AdListItemProps> = ({ item, onActionExecuted, showPlatform, hasTopListingSlots }) => {
  const { t } = useTranslation();

  const { formatRange } = useDate();
  const { format: formatCurrency } = useCurrencyFormatter(item.price.currency);

  const isNarrowLayout = useBreakpointValue({ base: true, lg: false });

  const publicationDates = useMemo(
    () => formatRange(item.publicationStart, item.publicationEnd),
    [formatRange, item.publicationEnd, item.publicationStart]
  );

  const price = useMemo(() => {
    if (item.price.onRequest) {
      return t('price.onRequest');
    }
    if (!item.price.amount) {
      return undefined;
    }
    if (item.price.vatClause) {
      return `${formatCurrency(item.price.amount)} ${item.price.vatClause}`;
    }
    return formatCurrency(item.price.amount);
  }, [formatCurrency, item, t]);

  const handleEditClick = (e: React.MouseEvent) => {
    if (item.allowEdit) {
      return;
    }

    e.preventDefault();
    onActionExecuted(item, 'showUneditableModal');
  };

  return (
    <Container>
      <ImageContainer>
        <StatusBadge status={item.status} />
        <StatusIcons>
          <VerificationStatus
            addressStatus={item.addressStatus}
            dataObjectStatus={item.status}
            dataObjectType={item.type}
          />
          <TopListingStatus isTopListed={item.isTopListed} />
        </StatusIcons>
        <RouterLink to={item.allowEdit ? routes.ad(item.id, CommonStepKeys.edit) : '#'} onClick={handleEditClick}>
          {item.imageUrl ? (
            <StyledImage src={item.imageUrl} alt={item.title} />
          ) : (
            <Flex height="full" flexShrink={0} marginBottom={2} justify="center" align="center">
              <Icon as={MissingImageIcon} boxSize={16} />
            </Flex>
          )}
        </RouterLink>
      </ImageContainer>
      <DetailsContainer>
        <Flex align="center" gap={2}>
          {showPlatform && <PlatformIcon name={item.platform || undefined} boxSize="16px" />}
          <Text fontSize="larger" fontWeight="bold">
            <RouterLink
              to={item.allowEdit ? routes.ad(item.id, CommonStepKeys.edit) : '#'}
              textDecoration="none"
              onClick={handleEditClick}
            >
              {item.title}
            </RouterLink>
          </Text>
        </Flex>
        {item.publicUrl && (
          <Link href={item.publicUrl} marginY={2} target="_blank">
            <Icon as={FaExternalLinkAlt} marginRight={2} />
            {t('myads.publicDetail')}
          </Link>
        )}
        {item.category && <Text>{item.category}</Text>}
        {item.description && <Text py={1}>{item.description}</Text>}
        <Box mt="auto">
          {item.address && <Text>{item.address}</Text>}
          <Flex gap={4} align="center">
            {publicationDates && <Text>{publicationDates}</Text>}
            <Text py={1} fontWeight="bold">
              {price}
            </Text>
          </Flex>
          <Text fontSize="sm" mt="auto">
            {t('myads.dataObjectId', { id: item.id })}
          </Text>
        </Box>
      </DetailsContainer>
      <ActionsContainer gap={1}>
        <Tooltip
          label={t('myads.views', { count: item.viewCount })}
          hasArrow
          placement="top"
          isDisabled={!isNarrowLayout}
        >
          <Flex align="center" gap={1} mb={2}>
            <Icon as={FaEye} boxSize="5" />
            {isNarrowLayout ? item.viewCount : t('myads.views', { count: item.viewCount })}
          </Flex>
        </Tooltip>
        {item.allowExtending && (
          <ActionButton
            iconType={FaClockRotateLeft}
            label={t('myads.actions.extendDuration')}
            onClick={() => onActionExecuted(item, 'extendDuration')}
            size={'lg'}
            variant="outline"
          />
        )}
        {item.allowEdit && (
          <ActionButton
            iconType={FaEdit}
            label={t('myads.actions.edit')}
            onClick={() => onActionExecuted(item, 'edit')}
            size={'lg'}
          />
        )}
        {item.allowDelete && (
          <ActionButton
            iconType={FaTrashAlt}
            label={t('myads.actions.delete')}
            onClick={() => onActionExecuted(item, 'delete')}
            size={'lg'}
          />
        )}
        {item.allowEnable && (
          <ActionButton
            iconType={FaCheckCircle}
            label={t('myads.actions.activate')}
            onClick={() => onActionExecuted(item, 'enable')}
            size={'lg'}
          />
        )}
        {item.allowDisable && (
          <ActionButton
            iconType={FaTimesCircle}
            label={t('myads.actions.deactivate')}
            onClick={() => onActionExecuted(item, 'disable')}
            size={'lg'}
          />
        )}
        <ActionButton
          iconType={FaCopy}
          label={t('myads.actions.duplicate')}
          onClick={() => onActionExecuted(item, 'duplicate')}
          size={'lg'}
        />
        {item.allowTopListing &&
          (item.isTopListed ? (
            <ActionButton
              iconType={FaArrowDown}
              label={t('myads.actions.disableTopListing')}
              onClick={() => onActionExecuted(item, 'disableTopListing')}
              size={'lg'}
            />
          ) : (
            hasTopListingSlots && (
              <ActionButton
                iconType={FaArrowUp}
                label={t('myads.actions.enableTopListing')}
                onClick={() => onActionExecuted(item, 'enableTopListing')}
                size={'lg'}
              />
            )
          ))}
      </ActionsContainer>
    </Container>
  );
};
