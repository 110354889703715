import React, { FunctionComponent } from 'react';
import { Input } from '@chakra-ui/react';

import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { AdAttribute } from '@/store/ad/types';

export type TextInputProps = {
  attribute: AdAttribute;
};

export const TextInput: FunctionComponent<TextInputProps> = ({ attribute }) => {
  const dispatch = useAppDispatch();
  const { formData } = useAppSelector((state) => state.ad);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const maxLength = attribute.maxLength;

    if (typeof maxLength === 'number' && maxLength > 0 && value.length > maxLength) {
      return;
    }
    dispatch(adActions.updateFormData({ key: attribute.id, value: e.target.value }));
  };

  return (
    <Input
      name={attribute.name || attribute.id.toString()}
      value={formData[attribute.id]?.value || ''}
      maxLength={attribute.maxLength || undefined}
      onChange={handleOnChange}
    />
  );
};
