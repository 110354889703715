const scriptTemplate = `<script id="{id}" src="{url}" type="module"></script>`;
const divTemplate = `<div class="nc-sdk" {attributes}></div>`;

export const getScriptCode = (id: string, url: string) => {
  return scriptTemplate.replace('{id}', id).replace('{url}', url);
};

export const getDivCode = (
  params: {
    type: string;
    component: string;
    apiUrl: string;
    platform: string;
    language?: string;
    filter?: string;
    theme?: string;
  } & Record<string, string | undefined>
) => {
  const attributes: string[] = [];
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const value = params[key];
      const attributeName = 'data-' + key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
      attributes.push(`${attributeName}='${value}'`);
    }
  }
  return divTemplate.replace('{attributes}', attributes.join(' '));
};
