import { FC } from 'react';
import { Box, BoxProps, Icon, Tooltip } from '@chakra-ui/react';
import { FaExclamationTriangle, FaInfoCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { AddressStatus, DataObjectStatus, DataObjectType } from '@netiva/classifieds-api';

import { isPrintType } from '@/lib/utils';

export type VerificationStatusProps = BoxProps & {
  addressStatus?: AddressStatus;
  dataObjectStatus?: DataObjectStatus;
  dataObjectType?: DataObjectType | number;
};

export const VerificationStatus: FC<VerificationStatusProps> = ({
  addressStatus,
  dataObjectStatus,
  dataObjectType,
  ...rest
}) => {
  const { t } = useTranslation();

  if (!addressStatus || addressStatus === 'Verified' || dataObjectStatus !== 'Active' || isPrintType(dataObjectType)) {
    return null;
  }

  return (
    <Box padding={1} {...rest}>
      {/* @ts-expect-error dynamic translation */}
      <Tooltip label={t(`myads.addressStatus.${addressStatus?.toLowerCase()}`)} placement="top" fontSize="md" hasArrow>
        <Box tabIndex={0}>
          <Icon
            as={addressStatus === 'New' ? FaExclamationTriangle : FaInfoCircle}
            boxSize={6}
            display="block"
            color="orange"
          />
        </Box>
      </Tooltip>
    </Box>
  );
};
