import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdFile, AdFormData, AdFormDataPayload, AdListItemValue, AdState, AdValidationStatus } from './types';
import { api, DataObjectStatus } from '@netiva/classifieds-api';

import { getIdOrFileName } from '@/lib/utils';

export * from './types';

const initialState: AdState = {
  formData: {},
  propertyFormData: {},
  images: [],
  documents: [],
  selectedExtensionProductIds: [],
  validationStatus: {},
  hasChanges: false,
  categorySearchText: '',
  dataObjectType: undefined,
  billingAddressId: undefined,
  categoryId: undefined,
  contactAddressId: undefined,
  locationAddressId: undefined,
  paymentProvider: undefined,
  platform: undefined,
  selectedContractId: undefined,
  selectedProductGroupIndex: undefined,
  selectedProductId: undefined,
  status: undefined,
  titleAttributeId: undefined,
  values: undefined,
  print: {
    bookedIssueIds: [],
    preview: undefined,
    previewContext: undefined,
    price: undefined,
    selectedIssueIds: [],
  },
};

export const adSlice = createSlice({
  name: 'ad',
  initialState,
  reducers: {
    /** update ad category */
    setCategoryId: (state, action: PayloadAction<number | undefined>) => {
      state.categoryId = action.payload;
    },
    /** update ad status */
    setStatus: (state, action: PayloadAction<DataObjectStatus>) => {
      state.status = action.payload;
    },
    /** update existing ad atrribute values */
    updateValues: (state, action: PayloadAction<AdListItemValue[] | undefined>) => {
      state.values = action.payload;
    },
    /** update single form data object */
    updateFormData: (state, action: PayloadAction<AdFormDataPayload>) => {
      state.formData[action.payload.key] = {
        value: action.payload.value,
        entries: action.payload.entries,
      };
      state.hasChanges = true;
    },
    /** update form data object in a bulk */
    bulkUpdateFormData: (state, action: PayloadAction<AdFormData>) => {
      state.formData = {
        ...state.formData,
        ...action.payload,
      };
    },
    /** update a single property value */
    updatePropertyFormData: (state, action: PayloadAction<AdFormDataPayload>) => {
      state.propertyFormData[action.payload.key] = {
        value: action.payload.value,
      };
      state.hasChanges = true;
    },
    /** update all property form values at once */
    bulkUpdatePropertyFormData: (state, action: PayloadAction<AdFormData>) => {
      state.propertyFormData = {
        ...state.propertyFormData,
        ...action.payload,
      };
    },
    /** reset all ad data */
    resetData: () => {
      return {
        ...initialState,
      };
    },
    addImage: (state, action: PayloadAction<AdFile>) => {
      state.images = [...state.images, { ...action.payload, order: state.images.length + 1 }];
      state.hasChanges = true;
    },
    setImages: (state, action: PayloadAction<AdFile[]>) => {
      state.images = action.payload;
      state.hasChanges = true;
    },
    removeImage: (state, action: PayloadAction<AdFile>) => {
      state.images = state.images.filter((img) => getIdOrFileName(img) !== getIdOrFileName(action.payload));
      state.hasChanges = true;
    },
    replaceImage: (state, action: PayloadAction<{ oldImage: AdFile; newImage: AdFile }>) => {
      state.images = state.images.map((img) =>
        getIdOrFileName(img) === getIdOrFileName(action.payload.oldImage) ? action.payload.newImage : img
      );
      state.hasChanges = true;
    },
    addDocument: (state, action: PayloadAction<AdFile>) => {
      state.documents = [
        ...state.documents.filter((doc) => getIdOrFileName(doc) !== getIdOrFileName(action.payload)),
        action.payload,
      ];
      state.hasChanges = true;
    },
    setDocuments: (state, action: PayloadAction<AdFile[]>) => {
      state.documents = action.payload;
      state.hasChanges = true;
    },
    removeDocument: (state, action: PayloadAction<AdFile>) => {
      state.documents = state.documents.filter((doc) => getIdOrFileName(doc) !== getIdOrFileName(action.payload));
      state.hasChanges = true;
    },
    replaceDocument: (state, action: PayloadAction<{ oldDocument: AdFile; newDocument: AdFile }>) => {
      state.documents = state.documents.map((doc) =>
        getIdOrFileName(doc) === getIdOrFileName(action.payload.oldDocument) ? action.payload.newDocument : doc
      );
      state.hasChanges = true;
    },
    setSelectedIssueIds: (state, action: PayloadAction<number[]>) => {
      state.print.selectedIssueIds = action.payload;
      state.hasChanges = true;
    },
    updateValidationStatus: (state, action: PayloadAction<AdValidationStatus>) => {
      state.validationStatus = action.payload;
    },
    setPlatform: (state, action: PayloadAction<string>) => {
      state.platform = action.payload;
    },
    setDataObjectType: (state, action: PayloadAction<number>) => {
      state.dataObjectType = action.payload;
    },
    setSelectedContractId: (state, action: PayloadAction<number | undefined>) => {
      state.selectedContractId = action.payload;
    },
    setSelectedProductId: (state, action: PayloadAction<number | undefined>) => {
      state.selectedProductId = action.payload;
    },
    selectExtensionProductId: (state, action: PayloadAction<number>) => {
      state.selectedExtensionProductIds = [...state.selectedExtensionProductIds, action.payload];
    },
    deselectExtensionProductId: (state, action: PayloadAction<number>) => {
      state.selectedExtensionProductIds = state.selectedExtensionProductIds.filter((id) => id !== action.payload);
    },
    setSelectedExtensionProductIds: (state, action: PayloadAction<number[]>) => {
      state.selectedExtensionProductIds = action.payload;
    },
    setBillingAddressId: (state, action: PayloadAction<number | undefined>) => {
      state.billingAddressId = action.payload;
    },
    setContactAddressId: (state, action: PayloadAction<number | undefined>) => {
      state.contactAddressId = action.payload;
    },
    setLocationAddressId: (state, action: PayloadAction<number | undefined>) => {
      state.locationAddressId = action.payload;
    },
    setCategorySearchText: (state, action: PayloadAction<string>) => {
      state.categorySearchText = action.payload;

      // prefill title with the search text
      if (state.titleAttributeId) {
        state.formData = { ...state.formData, [state.titleAttributeId]: { value: action.payload } };
      }
    },
    setSelectedProductGroupIndex: (state, action: PayloadAction<number | undefined>) => {
      state.selectedProductGroupIndex = action.payload;
    },
    setPaymentProvider: (state, action: PayloadAction<string | undefined>) => {
      state.paymentProvider = action.payload;
    },
    setMobileUploadContextId: (state, action: PayloadAction<string | undefined>) => {
      state.mobileUploadContextId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.getV1AccountDataObjectById.matchFulfilled, (state, { payload }) => {
      state.images = [];
      state.documents = [];
      payload.files.forEach((file) => {
        const location = file.locations.find((l) => l.type === 'original');
        if (!location) {
          return;
        }
        const adFile: AdFile = {
          id: file.id,
          fileName: file.name,
          url: location.url,
          order: parseInt(file.metadata?.find((m) => m.key === 'SortOrder')?.value || '0'),
        };
        if (file.type === 'Image' || file.type === 'PrintAdvertUploadedImg') {
          state.images.push(adFile);
        } else if (file.type === 'Pdf') {
          state.documents.push(adFile);
        }
      });
    });
    builder.addMatcher(api.endpoints.getV1AccountPrintAdvertById.matchFulfilled, (state, { payload }) => {
      state.images = payload.image
        ? [{ id: payload.image.id, fileName: payload.image.fileName, url: payload.image.url }]
        : [];
      state.print.previewContext = payload.eInseratContext;
      state.print.preview = payload.preview;
      state.print.price = payload.priceSummary;
      state.print.bookedIssueIds = payload.bookedIssueIds;
      state.print.selectedIssueIds = payload.selectedIssueIds;
      state.print.paymentInfo = payload.paymentInfo;
    });
    builder.addMatcher(api.endpoints.postV1AccountPrintAdvertPreview.matchFulfilled, (state, { payload }) => {
      if (!state.print.previewContext) {
        state.print.previewContext = payload.eInseratContext;
      }
      state.print.preview = payload.preview;
      state.print.price = payload.priceSummary;
      state.print.paymentInfo = payload.paymentInfo;
    });
    builder.addMatcher(api.endpoints.getV1AccountAttribute.matchFulfilled, (state, { payload }) => {
      if (payload.attributes.length > 0) {
        // get attribute ID by attribute key so we can set specific form data values automatically
        const titleAttribute = payload.attributes.find((a) => a.key === 'Title');
        if (titleAttribute) {
          state.titleAttributeId = titleAttribute.id;
        }
      }
    });
    builder.addMatcher(api.endpoints.getV1AccountPublicationOptions.matchFulfilled, (state, { payload }) => {
      if (payload.activePublication) {
        state.selectedContractId = payload.activePublication.contract.id;
        state.paymentProvider = payload.activePublication.contract.paymentProvider || undefined;
      }
    });
  },
});

export const adActions = adSlice.actions;
