import { FC } from 'react';

import { BaseUrl } from '@/environment';
import { Helmet } from 'react-helmet-async';

export const Plausible: FC = () => {
  return (
    BaseUrl === 'https://account.agrarmedien.ch' && (
      <Helmet>
        <script defer data-domain="account.agrarmedien.ch" src="https://plausible.agrarmedien.ch/js/script.js"></script>
      </Helmet>
    )
  );
};
