import { FC, PropsWithChildren, Suspense, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';

import { resetApiState } from '@netiva/classifieds-api';
import { MessagingServiceProvider, disableIPhoneZoom } from '@netiva/classifieds-common';
import { Loader } from '@netiva/classifieds-ui';

import { AuthenticationProvider } from './authentication';
import {
  AccountSelection,
  ConsentManager,
  GoogleTagManager,
  Matomo,
  Plausible,
  landingPageSelectionLocalStorageKey,
} from './components/global';
import { Router } from './components/router';
import { ToastContainer } from './components/ui';
import { useLoadAccountData } from './hooks';
import { polyfillCryptoRandomUUID } from './lib/polyfills';
import { routes } from './lib/routes';
import { enforceBaseUrl } from './lib/utils';
import { store, useAppDispatch, useAppSelector } from './store';
import theme, { faviconPath } from './theme';

import './i18n';

import '@fontsource/ibm-plex-sans/200.css';
import '@fontsource/ibm-plex-sans/200-italic.css';
import '@fontsource/ibm-plex-sans/400.css';
import '@fontsource/ibm-plex-sans/400-italic.css';
import '@fontsource/ibm-plex-sans/700.css';
import '@fontsource/ibm-plex-sans/700-italic.css';

const AppContent = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { isLoading, isLocked } = useAppSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();

  // fetch global data
  useLoadAccountData();

  // redirect to logout page when account is locked
  useEffect(() => {
    if (isLocked && !isLoading && location.pathname !== routes.logout()) {
      navigate(routes.logout());
    }
  }, [isLoading, isLocked, location.pathname, navigate]);

  // check local storage for "landingPageSelection" on initial load and redirect if exists
  useEffect(() => {
    const storedPathname = localStorage.getItem(landingPageSelectionLocalStorageKey);
    if (!isLoading && storedPathname && storedPathname !== location.pathname) {
      navigate(storedPathname, { replace: true });
    }
  }, [isLoading]);

  // reset API state on language change
  useEffect(() => {
    const onLanguageChanged = () => {
      dispatch(resetApiState());
    };

    i18n.on('languageChanged', onLanguageChanged);

    return () => {
      i18n.off('languageChanged', onLanguageChanged);
    };
  }, [dispatch, i18n]);

  return (
    <>
      <ConsentManager />
      <GoogleTagManager />
      <Matomo />
      <Plausible />
      <Helmet>
        <link rel="icon" href={faviconPath} />
      </Helmet>
      <AccountSelection />
      <Router />
      <ToastContainer />
    </>
  );
};

const AppProviders: FC<PropsWithChildren> = ({ children }) => {
  return (
    <BrowserRouter>
      <HelmetProvider>
        <ChakraProvider theme={theme}>
          <Suspense fallback={<Loader isLoading showOverlay />}>
            <AuthenticationProvider>
              <StoreProvider store={store}>
                <MessagingServiceProvider value={{ baseUrl: import.meta.env.VITE_MESSAGING_SERVICE_API_URL }}>
                  {children}
                </MessagingServiceProvider>
              </StoreProvider>
            </AuthenticationProvider>
          </Suspense>
        </ChakraProvider>
      </HelmetProvider>
    </BrowserRouter>
  );
};

const App = () => {
  polyfillCryptoRandomUUID();
  disableIPhoneZoom();
  enforceBaseUrl();

  return (
    <AppProviders>
      <AppContent />
    </AppProviders>
  );
};

export default App;
