import { FC } from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { CarouselConfiguration, ListDetailConfiguration } from './components';

export const SdkPage: FC = () => {
  const { t } = useTranslation();

  // TODO
  const showCarouselTab = window.location.href.match('(netiva|localhost)');

  return (
    <Tabs>
      <TabList>
        <Tab>{t('sdk.listDetail.title')}</Tab>
        {showCarouselTab && <Tab>{t('sdk.carousel.title')}</Tab>}
      </TabList>

      <TabPanels>
        <TabPanel>
          <ListDetailConfiguration />
        </TabPanel>
        {showCarouselTab && (
          <TabPanel>
            <CarouselConfiguration />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
};
