import { FunctionComponent } from 'react';
import { chakra } from '@chakra-ui/react';

import { useAppSelector } from '@/store';
import { AdAttribute, AdState } from '@/store/ad/types';
import { CheckboxInput } from './CheckboxInput';
import { HtmlInput } from './HtmlInput';
import { NumberInput } from './NumberInput';
import { SelectionInput } from './SelectionInput';
import { TextInput } from './TextInput';

export type AttributeInputProps = {
  attribute: AdAttribute;
};

const AttributeContainer = chakra('div', {
  baseStyle: {
    position: 'relative',
  },
});

const CharacterCount = chakra('span', {
  baseStyle: {
    position: 'absolute',
    top: -6,
    right: 0,
    fontSize: 'sm',
    color: 'neutral.300',
  },
});

export const AttributeInput: FunctionComponent<AttributeInputProps> = ({ attribute }) => {
  const { formData } = useAppSelector((state: { ad: AdState }) => state.ad);
  const value = formData[attribute.id]?.value || '';
  const maxLength = attribute.maxLength;

  if (!attribute.name || !attribute.editable) {
    return null;
  }

  const showCharCount =
    typeof maxLength === 'number' && maxLength > 0 && (typeof value === 'string' || typeof value === 'number');

  const charCount = showCharCount ? (
    <CharacterCount>
      {value.length} / {maxLength}
    </CharacterCount>
  ) : null;

  const isSingleSelect = attribute.type === 'SelectSingle' || attribute.type === 'SelectSingleSearchMulti';
  const isMultiSelect =
    attribute.type === 'SelectMultiAnd' ||
    attribute.type === 'SelectMultiOr' ||
    attribute.type === 'SelectMultiSearchSingle';

  if (isSingleSelect || isMultiSelect)
    return attribute.items ? (
      <SelectionInput id={attribute.id} name={attribute.name} options={attribute.items} isMulti={isMultiSelect} />
    ) : null;

  if (attribute.type === 'HTML') {
    return attribute.name ? <HtmlInput id={attribute.id} /> : null;
  }

  if (attribute.type === 'HTMLEInserat') {
    return attribute.name ? <HtmlInput id={attribute.id} isEinserat /> : null;
  }

  if (attribute.type === 'Boolean') {
    return <CheckboxInput id={attribute.id} name={attribute.name} />;
  }

  if (attribute.type === 'Integer' || attribute.type === 'Decimal' || attribute.type === 'Price') {
    return (
      <AttributeContainer>
        <NumberInput attribute={attribute} />
        {charCount}
      </AttributeContainer>
    );
  }

  return (
    <AttributeContainer>
      <TextInput attribute={attribute} />
      {charCount}
    </AttributeContainer>
  );
};
