import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  StackDivider,
  Text,
  useToast,
  useToken,
} from '@chakra-ui/react';
import { downloadFileFromUrl, resizeImage } from '@netiva/classifieds-common';
import { FileInput, FileInputClickBox, Loader } from '@netiva/classifieds-ui';
import { FileUploadHandler, useUploadService } from '@netiva/upload-service-react';
import { v4 as uuid } from 'uuid';

import { getIdOrFileName } from '@/lib/utils';
import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { AdFile } from '@/store/ad/types';
import { AdComponentProps } from '@/pages/Ad/types';
import { EditModal, ImagePanel } from './components';
import { useAd } from '@/pages/Ad/hooks';
import { UploadedFile } from '@/pages/Ad/providers';

import { BaseUrl, ImageUploadLimit, Instance } from '@/environment';

export type ImageSelectorProps = AdComponentProps & {
  multiple?: boolean;
  resizeWidth?: number;
  resizeHeight?: number;
};

export const ImageSelector: FC<ImageSelectorProps> = ({ multiple, resizeWidth = 1920, resizeHeight = 1080 }) => {
  const { t } = useTranslation();
  const toast = useToast();

  const dispatch = useAppDispatch();
  const { images, mobileUploadContextId } = useAppSelector((state) => state.ad);
  const { setUploadedFiles } = useAd();
  const [uploadedImage, setUploadedImage] = useState<File>();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [replacingImage, setReplacingImage] = useState<AdFile>();
  const [isResizing, setIsResizing] = useState(false);

  const hasImage = images.length > 0;

  const showInvalidFileToast = useCallback(
    (fileName: string) => {
      toast({
        status: 'error',
        title: t('ad.steps.files.images.invalidFile.title'),
        description: t('ad.steps.files.images.invalidFile.description', { name: fileName }),
        duration: 10000,
        isClosable: true,
      });
    },
    [t, toast]
  );

  const showFileLimitReachedNotification = (limit: number) => {
    toast({
      status: 'error',
      title: t('ad.steps.files.images.fileLimit.title'),
      description: t('ad.steps.files.images.fileLimit.description', { limit }),
      duration: 10000,
      isClosable: true,
    });
  };

  const createImageFromBlob = useCallback(
    async (blob: File | Blob) => {
      const file = new File([blob], `${uuid()}-${'name' in blob ? blob.name : ''}`, { type: blob?.type });
      setIsResizing(true);
      const resizedFile = await resizeImage(file, resizeWidth, resizeHeight);
      setIsResizing(false);
      const newImage: AdFile = {
        id: 0,
        fileName: resizedFile.name,
      };
      const newFile: UploadedFile = {
        fileName: newImage.fileName,
        file: resizedFile,
      };

      return { newImage, newFile };
    },
    [resizeHeight, resizeWidth]
  );

  const removeImage = useCallback(
    (image?: AdFile) => {
      const img = image || images[0];
      if (img) {
        setUploadedFiles((prev) => prev.filter((f) => getIdOrFileName(f) !== getIdOrFileName(img)));
        dispatch(adActions.removeImage(img));
      }
    },
    [dispatch, images, setUploadedFiles]
  );

  const handleMobileUpload = useCallback<FileUploadHandler>(
    async (files) => {
      if (!multiple) {
        removeImage();
      }

      for (const file of files) {
        const fileBlob = await downloadFileFromUrl(file.url, file.id);
        if (!fileBlob) {
          continue;
        }
        if (fileBlob.type.startsWith('image/')) {
          const { newImage, newFile } = await createImageFromBlob(fileBlob);
          setUploadedFiles((prev) => [...prev, newFile]);
          dispatch(adActions.addImage(newImage));
        } else {
          showInvalidFileToast(fileBlob.name);
        }
      }
    },
    [createImageFromBlob, dispatch, multiple, removeImage, setUploadedFiles, showInvalidFileToast]
  );

  const primaryColor = useToken('colors', 'primary.500');
  const logoUrl = `${BaseUrl}/assets/platforms/${Instance}/logo.svg`;
  const { contextId, qrCode } = useUploadService({
    apiBaseUrl: import.meta.env.VITE_UPLOAD_SERVICE_API_URL,
    allowedTypes: ['image/*;capture=camera'],
    allowMultiple: multiple || false,
    brandingLogoUrl: logoUrl,
    brandingColor: primaryColor,
    contextId: mobileUploadContextId,
    onFilesUploaded: handleMobileUpload,
    maxImageWidth: resizeWidth,
    maxImageHeight: resizeHeight,
  });

  useEffect(() => {
    dispatch(adActions.setMobileUploadContextId(contextId));
  }, [contextId, dispatch]);

  const handleSelectFiles = async (files: FileList) => {
    const totalImagesLength = files.length + images.length;

    if (totalImagesLength > ImageUploadLimit) {
      showFileLimitReachedNotification(ImageUploadLimit);
      return;
    }

    if (files.length === 1) {
      const file = files[0];
      if (file.type.startsWith('image/')) {
        setIsResizing(true);
        const resizedFile = await resizeImage(file, resizeWidth, resizeHeight);
        setIsResizing(false);
        setUploadedImage(resizedFile);
        setIsEditModalOpen(true);
      } else {
        showInvalidFileToast(file.name);
      }
    } else {
      // create a copy of the file list as it gets disposed during async execution
      const filesCopy = [...files];
      for (let i = 0; i < filesCopy.length; i++) {
        const file = filesCopy[i];
        if (file.type.startsWith('image/')) {
          const { newImage, newFile } = await createImageFromBlob(file);
          setUploadedFiles((prev) => [...prev, newFile]);
          dispatch(adActions.addImage(newImage));
        } else {
          showInvalidFileToast(file.name);
        }
      }
    }
  };

  const handlEditDone = async (blob?: Blob) => {
    setIsEditModalOpen(false);

    if (!blob) {
      return;
    }

    const { newImage, newFile } = await createImageFromBlob(blob);
    if (replacingImage || (!multiple && images.length)) {
      const oldImage = replacingImage || images[0];
      setUploadedFiles((prev) => prev.filter((f) => f.fileName !== oldImage.fileName).concat(newFile));
      dispatch(adActions.replaceImage({ oldImage, newImage }));
      setReplacingImage(undefined);
    } else {
      setUploadedFiles((prev) => [...prev, newFile]);
      dispatch(adActions.addImage(newImage));
    }
    setUploadedImage(undefined);
  };

  const handleRemove = (e: React.MouseEvent, image?: AdFile) => {
    e.preventDefault();
    e.stopPropagation();
    removeImage(image);
  };
  const handleReplace = (e: React.MouseEvent, image: AdFile) => {
    setReplacingImage(image);
  };

  return (
    <>
      <FormControl position="relative">
        <Loader isLoading={isResizing} showOverlay />
        <FormLabel>{t('ad.steps.files.images.label', { count: multiple ? 2 : 1, limit: ImageUploadLimit })}</FormLabel>
        <FileInput onFilesSelected={handleSelectFiles} accept="image/*;capture=camera" multiple={multiple}>
          <HStack width="full" divider={<StackDivider hideBelow="md" />}>
            <Flex width="full" direction="column" align="center">
              {!hasImage && <Text>{t('ad.steps.files.images.drag')}</Text>}
              <ImagePanel
                maxImageSize={multiple ? '150px' : '300px'}
                onRemoveImage={handleRemove}
                onReplaceImage={handleReplace}
                allowReorder={multiple}
                mb={4}
              />
              <FileInputClickBox>
                <Button type="button" size="sm" colorScheme="secondary">
                  {t(!multiple && hasImage ? 'ad.steps.files.images.replace' : 'ad.steps.files.images.upload')}
                </Button>
              </FileInputClickBox>
            </Flex>
            <Box hideBelow="md" ml="auto" maxW="250px" position="relative">
              <Text p={4}>
                {t(multiple ? 'ad.steps.files.images.qrCodeMulti' : 'ad.steps.files.images.qrCodeSingle')}
              </Text>
              <Image src={qrCode} fallback={<Loader isLoading />} alt="qr-code" />
            </Box>
          </HStack>
        </FileInput>
      </FormControl>

      <EditModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        file={uploadedImage}
        onDone={handlEditDone}
      />
    </>
  );
};
