import { FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, HStack, Heading, StackDivider, Text, VStack } from '@chakra-ui/react';
import { publicApi } from '@netiva/classifieds-api';
import { useScript } from '@netiva/classifieds-common';
import { CodeTextarea, FormInput, FormSelect, Loader, SelectOption } from '@netiva/classifieds-ui';

import { ApiBaseUrl } from '@/environment';
import { useAppSelector } from '@/store';
import { SdkParams } from '../types';
import { getDivCode, getScriptCode } from '../utils';

type CarouselSdkParams = SdkParams & {
  count?: number;
};

export const CarouselConfiguration: FC = () => {
  const { accountId } = useAppSelector((state) => state.account);
  const { t } = useTranslation();
  const [scriptVersion, setScriptVersion] = useState(0);
  const { data: platformsData, isLoading } = publicApi.useGetConfigurationPlatforms({});
  const platforms = useMemo(
    () =>
      platformsData?.items.filter(
        // exclude print platforms
        (p) => !p.dataObjectTypes.some((t) => t.type === 'EInserat' || t.type === 'ChMediaPdf')
      ) || [],
    [platformsData]
  );

  const form = useForm<CarouselSdkParams>({
    defaultValues: {
      accountId: accountId?.toString(),
      language: '',
      platform: platforms[0]?.name,
      count: 4,
    },
    mode: 'all',
  });
  const formValues = form.watch();

  const filter = useMemo(() => {
    let result: Record<string, string> = {};
    if (formValues.filter) {
      try {
        result = JSON.parse(formValues.filter);
      } catch {
        // ignore
      }
    }
    if (formValues.accountId) {
      result.accountId = formValues.accountId;
    }
    return result;
  }, [formValues]);

  const theme = useMemo(() => {
    if (formValues.theme) {
      try {
        return JSON.parse(formValues.theme);
      } catch {
        // ignore
      }
    }
    return {};
  }, [formValues]);

  const currentPlatform = useMemo(
    () => platforms.find((p) => p.name === formValues.platform),
    [formValues.platform, platforms]
  );

  useEffect(() => {
    if (platforms.length && !formValues.platform) {
      form.setValue('platform', platforms[0].name);
    }
  }, [form, formValues.platform, platforms]);

  const loaderScriptUrl = useMemo(() => {
    if (!currentPlatform) {
      return '';
    }
    const baseUrl = currentPlatform.publicBaseUrl;
    // const baseUrl = 'http://localhost:3000';
    return `${baseUrl}/scripts/sdk/loader-v1.js`;
  }, [currentPlatform]);

  const loaderScriptCode = useMemo(() => getScriptCode('nc-sdk-loader', loaderScriptUrl), [loaderScriptUrl]);

  const integrationCode = useMemo(() => {
    if (!formValues.platform) {
      return '';
    }
    return getDivCode({
      apiUrl: ApiBaseUrl,
      component: 'carousel',
      type: 'direct',
      filter: filter ? JSON.stringify(filter) : '',
      theme: theme ? JSON.stringify(theme) : '',
      language: formValues.language,
      platform: formValues.platform,
      paramCount: formValues.count?.toString(),
      recaptcha: 'false',
    });
  }, [filter, formValues, theme]);

  const previewCode = useMemo(() => {
    if (!formValues.platform) {
      return '';
    }
    return getDivCode({
      apiUrl: ApiBaseUrl,
      component: 'carousel',
      type: 'iframe',
      filter: filter ? JSON.stringify(filter) : '',
      theme: theme ? JSON.stringify(theme) : '',
      language: formValues.language,
      platform: formValues.platform,
      paramCount: formValues.count?.toString(),
      recaptcha: 'false',
    });
  }, [filter, formValues, theme]);

  useEffect(() => setScriptVersion((x) => x + 1), [previewCode]);
  useScript('nc-sdk-loader', `${loaderScriptUrl}?carouselRefresh=${scriptVersion}`);

  const platformOptions = useMemo<SelectOption[]>(
    () => platforms.map((p) => ({ label: p.name, value: p.name })),
    [platforms]
  );
  const languageOptions = useMemo<SelectOption[]>(
    () => [
      { label: '-', value: '' },
      { label: t('languages.de'), value: 'de' },
      { label: t('languages.fr'), value: 'fr' },
    ],
    [t]
  );

  return (
    <Box position="relative">
      <Loader isLoading={isLoading} showOverlay />
      <VStack align="stretch" divider={<StackDivider />} spacing={8}>
        <Box>
          <Heading fontSize="lg" my={2}>
            {t('sdk.carousel.title')}
          </Heading>
          <FormProvider {...form}>
            <VStack align="stretch" spacing={4}>
              <FormSelect<CarouselSdkParams>
                label={t('sdk.platform.label')}
                description={t('sdk.platform.helpText')}
                name="platform"
                options={platformOptions}
                isRequired
              />
              <FormSelect<CarouselSdkParams>
                label={t('sdk.language.label')}
                description={t('sdk.language.helpText')}
                name="language"
                options={languageOptions}
              />
              <FormInput<CarouselSdkParams>
                label={t('sdk.carousel.count.label')}
                description={t('sdk.carousel.count.helpText')}
                name="count"
              />
            </VStack>
          </FormProvider>
        </Box>
        <Box>
          <Heading fontSize="lg" my={2}>
            {t('sdk.code.title')}
          </Heading>
          <HStack divider={<StackDivider />} justify="stretch" align="flex-start" wrap={{ base: 'wrap', lg: 'nowrap' }}>
            <Box width="full" mb={4}>
              <Box>
                <Text mb={2}>{t('sdk.scriptCode.helpText')}</Text>
                <CodeTextarea value={loaderScriptCode} minHeight="60px" />
              </Box>
              <Box mt={4}>
                <Text mb={2}>{t('sdk.divCode.helpText')}</Text>
                <CodeTextarea value={integrationCode} minHeight="185px" />
              </Box>
            </Box>
            <Box width="full">
              <Text mb={2}>{t('sdk.preview')}</Text>
              <Box
                dangerouslySetInnerHTML={{ __html: previewCode }}
                width="full"
                height="full"
                border="default"
                padding={2}
                sx={{ iframe: { width: 'full' } }}
              ></Box>
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};
