import { FC } from 'react';

import { MatomoSiteId } from '@/environment';
import { Helmet } from 'react-helmet-async';

export const Matomo: FC = () => {
  return (
    MatomoSiteId && (
      <Helmet>
        <script>
          {`
          var _paq = window._paq = window._paq || [];
          /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
          (function() {
          var u="https://matomo.agrarmedien.ch/";
          _paq.push(['setTrackerUrl', u+'matomo.php']);
          _paq.push(['setSiteId', '${MatomoSiteId}']);
          var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
          g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
          })();
          </script>
          `}
        </script>
      </Helmet>
    )
  );
};
