import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';

import { paths } from '@/config';
import {
  AccountPage,
  AdPage,
  AddressesPage,
  AuthPage,
  HomePage,
  InvitationsPage,
  LoginPage,
  LoginsPage,
  LogoutPage,
  MyAdsPage,
  PaymentOverviewPage,
  PaymentPage,
  PaymentProcessingPage,
  PrintQuotePage,
  ProductsPage,
  ResetPage,
  SdkPage,
} from '@/pages';

import { LanguageRoute, PageRoute } from './components';
import { DefaultLayout, EmptyLayout } from '../layout';

export const Router: FC = () => {
  return (
    <Routes>
      {/* The ad page has its own layout */}
      <Route element={<EmptyLayout />}>
        <Route path={paths.AD} element={<PageRoute title="ad.edit.title" component={AdPage} requiresAccount />} />
        <Route path={paths.NEWAD} element={<PageRoute title="ad.create.title" component={AdPage} requiresAccount />} />
        <Route path={paths.RESET} element={<ResetPage />} />
      </Route>

      {/* Routes using the default layout */}
      <Route element={<DefaultLayout />}>
        <Route path={paths.HOME} element={<PageRoute title="home.title" component={HomePage} />} />
        <Route path={paths.ACCOUNT} element={<PageRoute title="account.title" component={AccountPage} />} />
        <Route
          path={paths.ADDRESSES}
          element={<PageRoute title="addresses.title" component={AddressesPage} requiresAccount />}
        />
        <Route path={paths.MYADS} element={<PageRoute title="myads.title" component={MyAdsPage} requiresAccount />} />
        <Route
          path={paths.LOGINS}
          element={<PageRoute title="logins.title" component={LoginsPage} requiresAccount />}
        />
        <Route path={paths.LOGIN} element={<PageRoute title="login.title" component={LoginPage} allowAnonymous />} />
        <Route path={paths.LOGOUT} element={<PageRoute title="logout.title" component={LogoutPage} allowAnonymous />} />
        <Route path={paths.INVITATIONS} element={<PageRoute title="invitations.title" component={InvitationsPage} />} />
        <Route
          path={paths.PRODUCTS}
          element={<PageRoute title="products.title" component={ProductsPage} requiresAccount />}
        />
        <Route
          path={paths.PAYMENT}
          element={<PageRoute title="payment.title" component={PaymentPage} allowAnonymous />}
        >
          <Route path={paths.PAYMENT_OVERVIEW} element={<PaymentOverviewPage />} />
          <Route path={paths.PAYMENT_PROCESSING} element={<PaymentProcessingPage />} />
        </Route>
        <Route
          path={paths.PRINT_QUOTE}
          element={<PageRoute title="printQuote.title" component={PrintQuotePage} allowAnonymous />}
        />
        <Route path={paths.SDK} element={<PageRoute title="sdk.title" component={SdkPage} />} />

        <Route path={paths.AUTH} element={<AuthPage />} />
        <Route path={paths.LANGUAGE} element={<LanguageRoute />} />
      </Route>
    </Routes>
  );
};
